h1 {
  font-size: 1.6rem;
  font-weight: 700;
}

.app {
  margin: 1rem;
}

.calcWrapper {
  display: flex;
  flex-direction: column;
}

.calcWrapper label {
  
}

.calcWrapper input[type="number"] {
  width: 50%;
  margin-bottom: .5rem;
}

.calcWrapper input[type="checkbox"] {
  width: 25px;
  margin-bottom: .5rem;
}

table {
  margin-top: 30px;
  width: 1100px;
}

table thead {
  font-weight: 900;
}

table td {
  text-align: right;
}